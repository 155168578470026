.cell {
  @apply px-5 py-3 text-xs text-gray-900 whitespace-nowrap;
}

.dense {
  @apply p-1;
}

.cell-string,
.cell-percentage,
.cell-date {
  @apply text-left;
}

.clickable {
  @apply cursor-pointer hover:text-metabase-blue;
}

.alignRight {
  @apply inline-block align-middle;
}

.alignCenter {
  @apply flex justify-center items-center;
}
