/**
 * Override the react-datepicker styles
 * From https://github.com/msnegurski/tailwind-react-datepicker/blob/master/styles/tailwind.css
 */
.react-datepicker__tab-loop {
  @apply absolute;
}

.react-datepicker__triangle {
  @apply text-white;
}

.react-datepicker-popper {
  @apply absolute top-1 z-10 left-0 text-sm transform-none bg-white shadow px-3 py-2;
}

.react-datepicker__month-container {
  @apply float-left;
}

/*
 * Time Picker
 */
.react-datepicker-time__input-container {
  display: none;
}

.react-datepicker__input-time-container {
  display: none;
}

.react-datepicker__time-container {
  @apply float-right pt-8;
}

.react-datepicker__time-list-item {
  @apply px-1 rounded py-0.5 text-sm text-gray-700 transition hover:bg-gray-200;
}

.react-datepicker__time-list-item--selected {
  @apply bg-blue-500 text-white hover:bg-blue-500 hover:text-white;
}

.react-datepicker__time-container .react-datepicker__time {
  overflow-y: auto;
}

.react-datepicker__time-list {
  height: 180px !important;
}

.react-datepicker-time__header {
  @apply text-gray-800 font-medium text-center text-xs mb-3;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  overflow-y: visible;
}
/* Time Picker End */

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-gray-800 font-medium text-center text-xs;
}

.react-datepicker__day-name {
  @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 hover:bg-gray-200 rounded;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-50 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white border-2 border-blue-500;
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white hover:bg-blue-500 hover:text-white;
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white;
}
.react-datepicker__aria-live {
  @apply hidden;
}
