/* Include Tailwind in your CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --metabase-blue: #5d9ae5;
}

html {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;

  /* use lato as the default font */
  font-family:
    "lato",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  line-height: 1.3em;
}

code {
  font-family:
    source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

iframe {
  width: 1px;
  min-width: 100%;
}

a {
  position: relative;
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}
