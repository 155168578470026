.zone {
  @apply flex justify-center items-center border-2 border-dashed border-gray-300 rounded-xl p-6;
}

.zoneHover {
  @apply border-gray-500;
}

.fileContainer {
  @apply flex items-end border-b pb-3 mb-3;
}
