.stepper {
  @apply py-2 px-3 !important;
}

.step {
  @apply cursor-auto bg-gray-300 !important;
  border: white 3px solid !important;
}

.completed {
  @apply bg-blue-400 opacity-50 !important;
}

.active {
  @apply bg-blue-400 opacity-100 border-blue-100 !important;
}

.nonLinear {
  @apply cursor-pointer !important;
}

.contentWrap {
  @apply w-full h-full overflow-y-auto mt-7;
}

.navigation {
  @apply mb-3 w-full py-3 bg-white mt-auto flex space-x-5 justify-end px-6;
}
