.table-wrapper {
  @apply shadow rounded overflow-x-auto overflow-y-auto;
}

.table {
  @apply table-auto w-full;
}

.table-body {
  @apply bg-white border-gray-300 divide-y divide-gray-300;
}

.table-head {
  /* this size not supported by tailwind classes unless we add custom sizes via config */
  font-size: 0.65rem;

  @apply divide-y divide-gray-200;
}

.table-head th {
  @apply bg-gray-50;
}

.table-head > tr {
  @apply divide-x divide-gray-200;
}

.table-head-sticky {
  @apply z-[9] bg-white sticky top-0;
}

.table-row-sticky {
  @apply border-t-2;
}

/* we use this class in table body cell and table header cell  */
:global(.toggleable) {
  animation: background-fade 3s ease-in-out;
}

@keyframes background-fade {
  0% {
    @apply bg-blue-50;
  }
  100% {
    background: none;
  }
}
