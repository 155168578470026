.container {
  position: relative;
  width: 70%;
  height: 22px;
  overflow: hidden;
  margin: 0 auto !important;
}

@keyframes duck-loading {
  0%,
  100% {
    left: 0;
    transform: rotateY(0deg);
  }
  45% {
    left: calc(100% - 22px);
    transform: rotateY(0deg);
  }
  50% {
    left: calc(100% - 22px);
    transform: rotateY(180deg);
  }
  95% {
    left: 0;
    transform: rotateY(180deg);
  }
}

.animate-duck-loading {
  position: absolute;
  height: 22px;
  width: 22px;
  animation: duck-loading 3s linear infinite;
}
