.hdr {
  @apply px-6 py-3 cursor-default font-normal;
}

.dense {
  @apply p-2;
}

.dense .label {
  font-size: 0.6rem;
}

.toggleable {
  @apply bg-white;
}

.container {
  @apply flex items-center space-x-1 flex-nowrap;
}

.label {
  @apply font-semibold text-gray-500 tracking-wider uppercase whitespace-nowrap;
}

.sortIconWrap {
  @apply text-gray-400 text-base flex flex-col -space-y-2 hover:text-metabase-blue cursor-pointer;
}

.filterIconWrap {
  @apply text-gray-400 flex flex-col hover:text-metabase-blue cursor-pointer;
}

.number .container:not(.alignCenter) {
  @apply justify-end;
}

.string,
.percentage,
.date {
  @apply text-left;
}

.alignRight {
  @apply justify-end;
}

.alignCenter {
  @apply justify-center;
}
