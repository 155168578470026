.Docs p {
  @apply text-base my-2;
}

.Docs > ol {
  @apply list-decimal;
}

.Docs > ul {
  @apply list-disc;
}

.Docs:not(.toc-wrapper) > ol,
.Docs:not(.toc-wrapper) > ul {
  @apply text-base break-words pl-6 mt-1 mb-2 leading-6;
}

.Docs.toc-wrapper > ol,
.Docs.toc-wrapper > ul {
  @apply list-none break-words pl-0 mt-1 mb-2 leading-6;
}

/* level paddings */
.Docs.toc-wrapper > ul ul {
  @apply pl-5;
}

.Docs:not(.toc-wrapper) > ul ul,
.Docs:not(.toc-wrapper) > ol ul {
  @apply list-disc m-0 pl-3 break-words;
}

/* level 1 */
.toc-wrapper > ul > li > a {
  @apply uppercase font-semibold;
}
/* level 2 */
.toc-wrapper > ul > li > ul > li > a {
  @apply font-semibold;
}
/* level 3 */
.toc-wrapper > ul > li > ul > li > ul > li > a {
  @apply font-semibold text-sm;
}
/* level 4 */
.toc-wrapper > ul > li > ul > li > ul > li > ul > li > a {
  @apply text-sm;
}

.Docs h1,
.Docs h2,
.Docs h5,
.Docs h6 {
  color: #262626;
}

.Docs h3 {
  color: #404040;
}

.Docs h4 {
  color: #737373;
}

.toc-wrapper > ul ul,
.toc-wrapper > ol ol {
  @apply list-none;
}

.Docs:not(.toc-wrapper) a {
  @apply text-blue-500;
}

.Docs.toc-wrapper a {
  @apply text-blue-600;
}

.Docs table {
  @apply text-base table-auto my-5;
}

.Docs table td,
.Docs table th {
  @apply border border-gray-300 px-2 py-1;
}

.Docs table th {
  @apply text-left font-semibold;
}

/**
  * Remove empty table headers
  * (we use bold to add sections so sometimes we dont want headers. BUT, If they are not added the table sometimes breaks - doesn't render properly)
  */
.Docs table th:empty {
  @apply hidden;
}
