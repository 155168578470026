.sizer {
  /* Tailwind does not have this cursor */
  cursor: ew-resize;
}

@media (max-width: 768px) {
  .logo {
    width: 100px;
    height: auto;
  }
}

@media (min-width: 768px) {
  .logo {
    width: 135px;
    height: auto;
  }
}
